import { Component, OnInit, Renderer2 } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Router } from '@angular/router';
import { InterventionModel } from './dataModels/intervention-model';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'slaveGenerico';

  token: string;
  device: any;
  inteventionObject: InterventionModel;
  loading: boolean = true;
  errorLoading: boolean = false;

  constructor(
    private remoteService: RemoteLibraryService,
    private router: Router,
    private renderer: Renderer2,
  ) {
    let aux = window.location.href.indexOf('handy') < 0 ? window.location.href.indexOf('anon') : window.location.href.indexOf('handy')
    this.token = window.location.href.slice(aux, window.location.href.length).split('/')[0];
    this.remoteService.interventionService.userToken = this.token;
  }

  private recorderInit = function (company) {
    const cookiesPolicy = (company.cookiesPolicy || {}).show;
    this.remoteService.interventionService.recorderInit(cookiesPolicy);
  };

  private loadCustomStyles = (company) => {
    // Loading company.styles.externalCustomStyles from company
    const externalCustomStyles = ((company || {}).styles || {}).externalCustomStyles;
    if (externalCustomStyles && externalCustomStyles.includes('.css')) {
      // Render the custom style url in <head>
      this.remoteService.htmlRender.loadCssStylesheet(this.renderer, externalCustomStyles as string);
    }
  }

  async ngOnInit(){
    await this.remoteService.commonService.overrideUserAgent();
    console.log('AppComponent overrode userAgent!');
    this.device = this.remoteService.interventionService.browserCheck();
    const intervention = await this.getInformation();
    this.recorderInit(intervention.company_config);
    this.remoteService.languageService.browserLanguage();
    /**
     * Custom Styles loading
     * We may load our customs styles EVEN AFTER all other styles,
     * including those injected in the component using this hack:
     * setTimeout(() => this.loadCustomStyles(intervention.company_config), 0);
     *
     * Maybe we should use RxJS instead... but for now:
     * Using setTimeout() will render the external stylesheet in THE LAST POSITION
     * inside document.head object
     */
    this.loadCustomStyles(intervention.company_config);
  }

  getInformation():Promise<InterventionModel> {
    return new Promise((resolve) => {
      this.remoteService.interventionService.getIntervention(this.token, this.device.userAgent).subscribe((data: any) => {
        this.remoteService.interventionService.actualIntervention = data;
        if(data.company_config && data.company_config.styles && data.company_config.styles.customStylesFolder) {
          this.remoteService.interventionService.actualIntervention.customStylesFolder = data.company_config.styles.customStylesFolder;
        }
        this.inteventionObject = this.remoteService.interventionService.actualIntervention;
        this.timeStamp2Date(this.inteventionObject.scheduled_date)
        if (this.inteventionObject.company_config) {
          this.remoteService.interventionService.setCompanyConfig(this.inteventionObject.company_config);
        }
        this.loading = false;
        this.remoteService.interventionService.removeIndexLoader();
        resolve(this.inteventionObject);
      }, (err) => {
        console.log("error getting intervention");
        this.errorLoading = true;
        this.loading = false;
        resolve(null);
      })
    });
  }

  timeStamp2Date(date2Convert) {
    let date = new Date(date2Convert*1000);
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    sessionStorage.setItem("scheduledTime",`${hour}:${minutes}`)
    sessionStorage.setItem("scheduledDate",`${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`)
  }
}
