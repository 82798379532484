import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RemoteLibraryService } from "remote-library";
import { InterventionModel } from "../dataModels/intervention-model";
import { AppComponent } from '../app.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  nextAvailable: Boolean = false;
  token: string;
  interventionObject: InterventionModel;
  device: any;
  errorLoading: boolean = false;
  loading: boolean = true;
  packagedVersion: String;
  hideRemotesToS: boolean;
  acceptTerms: Boolean = null;
  acceptConditionsCheck: Boolean = null;
  footerText = "";
  footerLink = "";
  cookiesPolicy: Boolean = false;
  showLegalFooter: Boolean = false;

  constructor(
    private router: Router,
    public remoteService: RemoteLibraryService,
    public appComponent: AppComponent
  ) {
    this.token = this.remoteService.interventionService.userToken;
    if (this.remoteService.interventionService.packagedVersion) {
      this.packagedVersion = this.remoteService.interventionService.packagedVersion;
      console.log('VERSION: ', this.packagedVersion);
    }
    this.interventionObject = this.appComponent.inteventionObject;
  }

  ngOnInit() {
    const cancelled = 4;
    this.getInformation();

    if (this.interventionObject && this.interventionObject.status !== cancelled && !this.interventionObject['errorMessage']) {
      this.device = this.remoteService.interventionService.browserCheck();
      this.checkConditions();
      this.remoteService.languageService.browserLanguage();
      this.getLabelInformation();
      this.errorLoading = false;
    } else {
      this.errorLoading = true;
      this.loading = false;
    }
  }

  getLabelInformation() {
    this.interventionObject = this.remoteService.interventionService.actualIntervention;
    if (this.interventionObject.company_config && this.interventionObject.company_config.customLabelSheet) {
      this.remoteService.languageService.getCustomLabels(
        this.interventionObject.company_config.customLabelSheet,
        this.remoteService.languageService.browserLanguage()).then(() => {
          if (this.remoteService.languageService.labels) {
            this.footerText = this.remoteService.languageService.labels.customSlaveFooter || "";
            this.footerLink = this.remoteService.languageService.labels.customSlaveLink || "";
          }
        });
    }
  }

  getInformation() {
    this.interventionObject = this.remoteService.interventionService.actualIntervention;
    if (this.interventionObject.company_config) {
      if (this.interventionObject.company_config.labels) {
        this.footerText = this.interventionObject.company_config.labels.customSlaveFooter || "";
        this.footerLink = this.interventionObject.company_config.labels.customSlaveLink || "";
      }

      const { cookiesPolicy, showLegalFooter} = this.interventionObject.company_config;
      if (cookiesPolicy && cookiesPolicy.show) {
        const analyticalCookiesAcceptance = localStorage.getItem('analyticalCookiesAcceptance');
        if (analyticalCookiesAcceptance !== 'true' && analyticalCookiesAcceptance !== 'false') {
          this.cookiesPolicy = true;
        }
      }
      this.showLegalFooter = showLegalFooter;
    }
    this.loadDynamicStyles(this.interventionObject.customStylesFolder);
    this.hideRemotesToS = this.remoteService.interventionService.hideRemotesToS;
    if (this.remoteService.interventionService.hideRemotesToS) {
      console.log('ToS escondidos por configuracion.');
      this.nextAvailable = true;
    }

    this.loading = false;
  }

  public checkConditions() {
    let conditionsAccepted = JSON.parse(localStorage.getItem("conditionsAccepted")) || [];
    if (conditionsAccepted.indexOf(this.interventionObject.id) !== -1) {
      if (this.interventionObject.status === 3) {
        this.router.navigate([`final-page/${this.token}`]);
      } else {
        this.router.navigate([`call/${this.token}`]);
      }
    }
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
        sessionStorage.setItem("customStylesFolder", customStylesFolder);
      } catch (error) {
      }
    }
  }

  checkLegalConditions() {
    this.acceptConditionsCheck = !this.acceptConditionsCheck;
    if (this.remoteService.interventionService.smartvideosplitToS) {
      this.nextAvailable = this.acceptConditionsCheck && this.acceptTerms;
    } else {
      this.nextAvailable = this.acceptConditionsCheck;
    }
  }
  checkTerms() {
    this.acceptTerms = !this.acceptTerms;
    this.nextAvailable = this.acceptConditionsCheck && this.acceptTerms;
  }

  /**
   * @description navegar hasta el check de permisos y mostrar mensaje.
   */
  goToTerms() {
    if (!this.nextAvailable) {
      document.getElementById('warning').scrollIntoView();
      this.acceptTerms = !this.acceptTerms ? false : true;
      this.acceptConditionsCheck = !this.acceptConditionsCheck ? false : true;
    }
  }

  acceptConditions() {
    let conditionsAccepted = JSON.parse(localStorage.getItem("conditionsAccepted")) || [];
    conditionsAccepted.push(this.interventionObject.id);
    localStorage.setItem("conditionsAccepted", JSON.stringify(conditionsAccepted));
    if (!this.interventionObject.termsAndConditionsAcceptedAt) {
      this.remoteService.interventionService.update(this.token, this.interventionObject.id, {
        'termsAndConditionsAcceptedAt': Date.now(),
      }).pipe(take(1)).subscribe();
    }
    // Initialice smartlook for the first time
    // right after the permissions have already been accepted
    this.remoteService.interventionService.recorderIdentify();
    this.router.navigate([`call/${this.token}`]);
  }

  viewConditions() {
    if (this.remoteService.interventionService.smartvideosplitToS) {
      this.router.navigate([`use-conditions/${this.token}/use`]);
    }
    else {
      this.router.navigate([`use-conditions/${this.token}/all`]);
    }

  }
  viewTerms() {
    this.router.navigate([`use-conditions/${this.token}/terms`]);
  }

}
